import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { baseMoveUri, baseUri, baseUriAssetUpload } from '../constants/uri.constants';
import { BASE_URI_CONFIG, BaseUriConfig, DbUtils, HttpUtils, ObjectUtils, StringUtils } from '@smooved/core';
import { map } from 'rxjs/operators';
import { Transaction } from '../classes/transaction';

@Injectable({
    providedIn: 'root',
})
export class TransactionService {
    private apiUriParam = { apiUri: this.baseUriConfig.apiUri };

    constructor(
        private readonly httpClient: HttpClient,
        @Inject(BASE_URI_CONFIG) private readonly baseUriConfig: BaseUriConfig
    ) {}

    public searchTransaction(
        searchString: string,
        pageIndex: number,
        pageSize: number
    ): Observable<{
        transactions: Transaction[];
    }> {
        return this.httpClient.get<{ transactions: Transaction[] }>(StringUtils.parseUri(baseUri, this.apiUriParam), {
            params: HttpUtils.buildQueryParameters({ searchString, pageIndex, pageSize }, true, true),
        });
    }

    public getTransaction(transactionId: string): Observable<Transaction> {
        return this.httpClient.get<Transaction>(StringUtils.parseUri(`${baseUri}/${transactionId}`, this.apiUriParam));
    }

    public uploadEnergyMeterReadingAsset(transactionId: string, content: FormData): Observable<any> {
        return this.httpClient.post<any>(StringUtils.parseUri(baseUriAssetUpload, { ...this.apiUriParam, id: transactionId }), content);
    }

    public deleteEnergyAsset(moveId: string, key: string): Observable<any> {
        return this.httpClient.delete<any>(StringUtils.parseUri(`${baseMoveUri}/energy/all-assets`, { ...this.apiUriParam, id: moveId }), {
            params: {
                key: encodeURIComponent(key),
            },
        });
    }

    /**
     * For now, this will use the move endpoint to update
     */
    public updateTransaction(transactionId: string, payload: any): Observable<string> {
        return this.httpClient
            .patch(
                StringUtils.parseUri(baseMoveUri, {
                    ...this.apiUriParam,
                    id: transactionId,
                }),
                payload
            )
            .pipe(
                map((result) => {
                    return DbUtils.getStringId(result);
                })
            );
    }
}
